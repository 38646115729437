import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import { graphql, useStaticQuery } from "gatsby";

import SocialIcons from "./SocialIcons";
import SearchBar from "./SearchBar";

const StyledTopbarInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

const StyledTopbar = styled.div`
  background: rgb(6, 6, 6);
  color: rgb(230, 230, 230);
  padding: 0.66rem 0;
  position: relative;

  a {
    color: #84b1ea;
    text-decoration: none;

    &:hover {
      color: #fefefe;
    }
  }

  div.announcement {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    font-size: 1rem;
    width: 100%;
    padding: 0;
    margin-right: 2rem;
    padding-bottom: 1rem;

    @media (min-width: 768px) {
      width: unset;
      justify-content: flex-start;
      padding-bottom: 0;
    }

    span {
      padding: 0;
      display: block;
      line-height: 1;
    }

    p {
      margin: 0 0 0 5px;
      padding: 0;
      /* font-size: 0.85rem; */
      line-height: 1.4;
      text-transform: uppercase;

      a {
        font-weight: 600;
        letter-spacing: -0.03rem;
      }
    }
  }
`;

const Topbar = () => {
  const data = useStaticQuery(graphql`
    query topBarQuery {
      prismicSharedConfigs {
        data {
          contact_email
          phone_number
        }
      }
    }
  `);

  return (
    <StyledTopbar>
      <Container>
        <Row>
          <Col xs={12}>
            <StyledTopbarInner>
              <div className="announcement">
                <span>
                  <FaPhone />
                </span>{" "}
                <p>
                  Call Us:{" "}
                  <a
                    href={`tel:${data.prismicSharedConfigs.data.phone_number}`}
                  >
                    (703) 333-2822
                  </a>
                  <span>
                    <b>DCJS ID:</b> 88-1202
                  </span>
                </p>
              </div>

              <SocialIcons size="1.5" />
              <SearchBar />
            </StyledTopbarInner>
          </Col>
        </Row>
      </Container>
    </StyledTopbar>
  );
};
export default Topbar;
