import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

const StyledSearchTab = styled.div`
  /* background-color: #b5dffd; */
  padding: 0;
  margin: 0 0 0 auto;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  input {
    padding: 0.2rem;
    line-height: 1.1;
    outline: none;
    border: none;
    border-radius: 0;
    font-size: 14px;
    background: none;
    border-bottom: 1px solid #333;
    color: #bbb;

    &:focus,
    &:active {
      background: none;
      color: #ccc;
      outline: none;
      box-shadow: none;
      border-color: #999;
    }
  }

  button {
    background: #142548;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 1px 1px 1px #333;
    padding: 6px 12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover {
      background-color: #258df5;
    }
  }
`;

const SearchBar = () => {
  // const [query, setQuery] = useState("");

  const handleSubmit = vals => {
    navigate(`/search/?q=${encodeURI(vals.query)}`);
  };

  return (
    <StyledSearchTab>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <label htmlFor="query" className="sr-only">
            Search:
          </label>
          <Field
            name="query"
            className="form-control"
            placeholder="search"
            id="query"
          />
          <button type="submit" name="search" aria-label="submit search">
            <FaSearch />
          </button>
        </Form>
      </Formik>
    </StyledSearchTab>
  );
};

export default SearchBar;
