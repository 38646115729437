import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FaFacebookSquare, FaTwitterSquare, FaInstagram } from "react-icons/fa";
import styled from "styled-components";

const StyledSocials = styled.ul`
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0.5rem 0 0;

    &:last-child {
      margin: 0;
    }

    a {
      display: inline-block;
      font-size: ${props => (props.size ? `${props.size}rem` : "1.5rem")};
      line-height: 1;
    }
  }
`;

const SocialIcons = () => {
  const data = useStaticQuery(graphql`
    query SocialIconsQuery {
      headerLogo: file(name: { eq: "site_logo" }) {
        childImageSharp {
          fixed(quality: 90, width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      prismicSharedConfigs {
        data {
          facebook_url {
            url
          }
          instagram_url {
            url
          }
          twitter_url {
            url
          }
        }
      }
    }
  `);

  const {
    facebook_url,
    twitter_url,
    instagram_url,
  } = data.prismicSharedConfigs.data;

  return (
    <StyledSocials className="socials">
      {facebook_url.url.length > 0 && (
        <li>
          <a href={facebook_url.url} aria-label="Facebook">
            <FaFacebookSquare />
          </a>
        </li>
      )}

      {twitter_url.url.length > 0 && (
        <li>
          <a href={twitter_url.url} aria-label="Twitter">
            <FaTwitterSquare />
          </a>
        </li>
      )}

      {instagram_url.length > 0 && (
        <li>
          <a href={instagram_url.url} aria-label="Instagram">
            <FaInstagram />
          </a>
        </li>
      )}
    </StyledSocials>
  );
};

export default SocialIcons;
