import axios from "axios";
import { navigate } from "@reach/router";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

let toastID = "toasty";

export const isBrowser = () => typeof window !== "undefined";

export const handleLogin = async (inputs, siteContext) => {
  if (inputs.email && inputs.password && inputs.password.length >= 4) {
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/users/getToken`,
        inputs,
        {
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response.data && response.data.token) {
        siteContext.updateToken(response.data.token);
        navigate("/dashboard/");
        return siteContext.makeToast("Login successful.", "success");
      }

      return siteContext.makeToast("Invalid login.", "error");
    } catch (e) {
      return siteContext.makeToast("Invalid login.", "error");
    }
  }

  return siteContext.makeToast("Could not login.", "error");
};

export const handleLogout = siteContext => siteContext.removeToken();
// return navigate("/dashboard/login");

export const handleAdminLogin = async (inputs, siteContext) => {
  if (inputs.email && inputs.password && inputs.password.length >= 4) {
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/users/getToken`,
        inputs
      );

      if (
        response.data &&
        response.data.token &&
        jwtDecode(response.data.token).data.role === "admin"
      ) {
        siteContext.makeToast("Login successful.", "success");
        siteContext.updateToken(response.data.token);

        navigate("/admin/dashboard");
        return null;
      }

      siteContext.makeToast("Invalid login.", "error");
      return null;
    } catch (e) {
      siteContext.makeToast("Invalid login.", "error");
      return null;
    }
  }

  return {
    error: true,
    message: "Could not log in.",
  };
};

export const isLoggedIn = siteContext => {
  if (!isBrowser()) {
    return false;
  }

  // const token = siteContext.userToken;
  const token = siteContext.getCookieToken();

  const tokenLength = token.length || 0;
  if (tokenLength === 0) {
    return false;
  }

  // decode token
  const decoded = jwtDecode(token);
  const now = Date.now().valueOf();
  const nowUnix = parseInt(now / 1000);
  // console.log("decoded.exp", decoded.exp);

  if (decoded.exp < nowUnix) {
    if (!toast.isActive(toastID)) {
      toastID = toast("Your login session has expired. Please login again.", {
        position: "top-left",
        type: "error",
        toastId: "toasty",
      });
    }
    return false;
  }

  if (!decoded.data.id) {
    siteContext.makeToast("Please login again.", "error");
    return false;
  }

  return true;
};

export const isLoggedInAdmin = siteContext => {
  if (!isBrowser()) {
    return false;
  }
  // console.log("siteContext.userToken: ", siteContext.userToken);

  // const token = siteContext.userToken;
  const token = siteContext.getCookieToken();
  const tokenLength = token.length || 0;

  if (tokenLength === 0) {
    // siteContext.makeToast("Please login.", "error");
    return false;
  }

  // decode token
  const decoded = jwtDecode(token);
  const now = Date.now().valueOf();
  const nowUnix = parseInt(now / 1000);
  // console.log("decoded", decoded);
  // console.log("nowUnix", nowUnix);

  if (decoded.exp < nowUnix) {
    // siteContext.removeToken();
    // navigate("/admin/dashboard/login");
    if (!toast.isActive(toastID)) {
      toastID = toast("Your login session has expired. Please login again.", {
        position: "top-left",
        type: "error",
        toastId: "toasty",
      });
    }
    return false;
  }

  if (!decoded.data.id) {
    siteContext.makeToast("Please login again.", "error");
    return false;
  }

  if (decoded.data.role !== "admin") {
    return false;
  }

  return true;
};
