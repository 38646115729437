import React, { useContext, useEffect, useState } from "react";
// import { graphql, useStaticQuery } from "gatsby";
// import { navigate } from "@reach/router";
import styled from "styled-components";
import { toast } from "react-toastify";

import { cartContext } from "providers/CartProvider";
import { isLoggedIn, handleLogout } from "services/auth";

// import SearchBar from "components/SearchBar";
import Navbar from "./NavBar";
import Footer from "./Footer";
import Topbar from "./Topbar";
import BottomBar from "./BottomBar";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main.main-content {
    flex: 1;
    padding: 0;
  }
`;

const StyledLogoutTab = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  background: red;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  button {
    background: none;
    border: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 1px 1px 1px #333;
    padding: 4px 12px;

    &:hover {
      background: #ff3d3d;
    }
  }
`;

toast.configure();

const Layout = ({ children, pageInfo }) => {
  const siteContext = useContext(cartContext);

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (isLoggedIn(siteContext)) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [siteContext]);

  const logout = () => {
    siteContext.makeToast("Logged out!");
    handleLogout(siteContext);
  };

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <StyledWrapper>
      <Topbar />
      <Navbar pageInfo={pageInfo} isAuth={isAuth} />
      <main className="main-content">
        <>
          {isAuth && (
            <div className="container" style={{ position: "relative" }}>
              <StyledLogoutTab className="logout-tab">
                <button type="button" onClick={logout}>
                  Logout
                </button>
              </StyledLogoutTab>
            </div>
          )}
          {children}
        </>
      </main>
      <Footer />
      <BottomBar />
    </StyledWrapper>
  );
};

export default Layout;
