import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledBottomBar = styled.div`
  border-top: 1px solid rgb(80, 80, 80);
  background: rgb(6, 6, 6);
  padding: 1rem 0;
  line-height: 1;

  div.copyright {
    color: #bbb;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  div.siteby {
    color: #333;
    font-size: 0.8rem;

    a {
      color: #555;
    }
  }
`;

const BottomBar = () => (
  <StyledBottomBar>
    <Container className="d-flex flex-row align-items-center justify-content-between">
      <div className="copyright">
        &copy; {new Date().getFullYear()}, D&D Security Training Academy
      </div>

      <div className="siteby">
        site: <a href="https://www.stoutlabs.com">stoutlabs</a>
      </div>
    </Container>
  </StyledBottomBar>
);

export default BottomBar;
