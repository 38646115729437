import React, { useContext } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Container, Navbar, Nav } from "react-bootstrap";
import { FaBars } from "react-icons/fa";

import { cartContext } from "providers/CartProvider";

const StyledNavbar = styled(Navbar)`
  background: rgb(17, 17, 17);
`;

const CustomNavbar = ({ pageInfo, isAuth }) => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      headerLogo: file(name: { eq: "site_logo" }) {
        childImageSharp {
          fixed(quality: 90, width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const siteContext = useContext(cartContext);

  return (
    <>
      <StyledNavbar expand="lg" id="site-navbar">
        <Container>
          <Link to="/" className="link-no-style brand" aria-label="DDSTA Logo">
            <Navbar.Brand as="span">
              <Img fixed={data.headerLogo.childImageSharp.fixed} />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FaBars />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link
                to="/class-schedules/"
                className="link-no-style"
                activeClassName="active"
                partiallyActive
              >
                Class Schedules
              </Link>

              <Link
                to="/calendars/"
                className="link-no-style"
                activeClassName="active"
                partiallyActive
              >
                Calendar
              </Link>

              <Link
                to="/faqs/"
                className="link-no-style"
                activeClassName="active"
              >
                FAQs
              </Link>

              <Link
                to="/about-us/"
                className="link-no-style"
                activeClassName="active"
              >
                About Us
              </Link>

              <Link
                to="/find-us/"
                className="link-no-style"
                activeClassName="active"
              >
                Find Us
              </Link>

              <Link
                to="/inservice-renewals/"
                className="link-no-style"
                activeClassName="active"
              >
                Renewals
              </Link>

              <Link
                to="/contact-us/"
                className="link-no-style"
                activeClassName="active"
              >
                Contact Us
              </Link>
            </Nav>

            <Nav className="ml-auto">
              <Link to="/cart" className="text-warning font-weight-bold pr-0">
                Your Cart:{" "}
                {siteContext && siteContext.cart && siteContext.cart.length}
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </>
  );
};

export default CustomNavbar;
