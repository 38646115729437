import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import {
  FaRegEnvelope,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa/index.esm";

import SocialIcons from "./SocialIcons";
import { HTMLContent } from "./Content";

/*
query={graphql`
  query {
    footerLogo: file(name: { eq: "site_logo" }) {
      childImageSharp {
        fixed(quality: 90, width: 74) {
          ...GatsbyImageSharpFixed
        }
      }
    }


  }
`}


*/
const MainFooter = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      prismicSharedConfigs {
        data {
          contact_email
          phone_number

          range_address {
            text
          }
          classroom_address {
            text
          }
        }
      }

      footerLogo: file(name: { eq: "site_logo" }) {
        childImageSharp {
          fixed(quality: 90, width: 74) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const {
    contact_email,
    phone_number,
    range_address,
    classroom_address,
  } = data.prismicSharedConfigs.data;

  const classroomAddy = `<p>${classroom_address.text.replace(
    /\n/g,
    "<br />"
  )}</p>`;

  const rangeAddy = `<p>${range_address.text.replace(/\n/g, "<br />")}</p>`;

  return (
    <footer className="py-5 mainfooter">
      <Container>
        <Row>
          <Col xs="12" md="6" lg="3">
            <Img
              fixed={data.footerLogo.childImageSharp.fixed}
              className="mr-3"
            />

            <p>
              D&D Security Training Academy provides a full line of security and
              law enforcement related training services, both locally and to
              various locations in the U.S. and overseas.
            </p>

            <p>
              <b>DCJS ID:</b> 88-1202
            </p>
          </Col>

          <Col xs="12" md="6" lg="3" className="text-md-right text-lg-left">
            <h4>Links</h4>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/class-schedules/">Class Schedules</Link>
              </li>
              <li>
                <Link to="/faqs/">FAQs</Link>
              </li>
              <li>
                <Link to="/about-us/">About Us</Link>
              </li>
              <li>
                <Link to="/find-us/">Find Us</Link>
              </li>
              <li>
                <Link to="/inservice-renewals/">Renewals</Link>
              </li>
              <li>
                <Link to="/contact-us/">Contact Us</Link>
              </li>
            </ul>
          </Col>

          <Col xs="12" md="6" lg="3" className="footer-locations mt-4 mt-lg-0">
            <h4>Training Locations:</h4>
            <h5>Classroom</h5>

            <HTMLContent className="address" content={classroomAddy} />
            <p>
              <a
                href="https://goo.gl/maps/RK1spbPsMrE3j5bR7"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-directions"
              >
                <FaMapMarkerAlt /> Get Directions
              </a>
            </p>

            {/* <h5>The Range</h5>

            <HTMLContent className="address" content={rangeAddy} />

            <p>
              <a
                href="https://goo.gl/maps/Fcufj9cxtiqp87WX6"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-directions"
              >
                <FaMapMarkerAlt /> Get Directions
              </a>
            </p> */}
          </Col>

          <Col xs="12" md="6" lg="3" className="text-md-right mt-4 mt-lg-0">
            <h4>Contact Info</h4>

            <p>
              P.O. Box 22542 <br />
              Alexandria, VA 22304
            </p>

            <p>
              <a href={`tel:${phone_number}`}>
                <FaPhone /> {phone_number}
              </a>
            </p>

            <p>
              <a href={`mailto: ${contact_email}`}>
                <FaRegEnvelope /> {contact_email}
              </a>
            </p>

            <SocialIcons />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MainFooter;
